import service from '@/utils/request';

// @Tags SysGroupUser
// @Summary 创建SysGroupUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupUser true "创建SysGroupUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroupUser/createSysGroupUser [post]
export var createSysGroupUser = function createSysGroupUser(data) {
  return service({
    url: "/sysGroupUser/createSysGroupUser",
    method: 'post',
    data: data
  });
};

// @Tags SysGroupUser
// @Summary 删除SysGroupUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupUser true "删除SysGroupUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroupUser/deleteSysGroupUser [delete]
export var deleteSysGroupUser = function deleteSysGroupUser(data) {
  return service({
    url: "/sysGroupUser/deleteSysGroupUser",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroupUser
// @Summary 删除SysGroupUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysGroupUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroupUser/deleteSysGroupUser [delete]
export var deleteSysGroupUserByIds = function deleteSysGroupUserByIds(data) {
  return service({
    url: "/sysGroupUser/deleteSysGroupUserByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroupUser
// @Summary 更新SysGroupUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupUser true "更新SysGroupUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysGroupUser/updateSysGroupUser [put]
export var updateSysGroupUser = function updateSysGroupUser(data) {
  return service({
    url: "/sysGroupUser/updateSysGroupUser",
    method: 'put',
    data: data
  });
};

// @Tags SysGroupUser
// @Summary 用id查询SysGroupUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupUser true "用id查询SysGroupUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysGroupUser/findSysGroupUser [get]
export var findSysGroupUser = function findSysGroupUser(params) {
  return service({
    url: "/sysGroupUser/findSysGroupUser",
    method: 'get',
    params: params
  });
};

// @Tags SysGroupUser
// @Summary 分页获取SysGroupUser列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysGroupUser列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroupUser/getSysGroupUserList [get]
export var getSysGroupUserList = function getSysGroupUserList(params) {
  return service({
    url: "/sysGroupUser/getSysGroupUserList",
    method: 'get',
    params: params
  });
};